// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-career-profiles-js": () => import("./../../../src/pages/career-profiles.js" /* webpackChunkName: "component---src-pages-career-profiles-js" */),
  "component---src-pages-citations-js": () => import("./../../../src/pages/citations.js" /* webpackChunkName: "component---src-pages-citations-js" */),
  "component---src-pages-family-activities-js": () => import("./../../../src/pages/family-activities.js" /* webpackChunkName: "component---src-pages-family-activities-js" */),
  "component---src-pages-health-equity-js": () => import("./../../../src/pages/health-equity.js" /* webpackChunkName: "component---src-pages-health-equity-js" */),
  "component---src-pages-health-worker-js": () => import("./../../../src/pages/health-worker.js" /* webpackChunkName: "component---src-pages-health-worker-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manufacturing-scientist-js": () => import("./../../../src/pages/manufacturing-scientist.js" /* webpackChunkName: "component---src-pages-manufacturing-scientist-js" */),
  "component---src-pages-operations-manager-js": () => import("./../../../src/pages/operations-manager.js" /* webpackChunkName: "component---src-pages-operations-manager-js" */),
  "component---src-pages-patient-affairs-js": () => import("./../../../src/pages/patient-affairs.js" /* webpackChunkName: "component---src-pages-patient-affairs-js" */),
  "component---src-pages-pharmaceutical-sciences-js": () => import("./../../../src/pages/pharmaceutical-sciences.js" /* webpackChunkName: "component---src-pages-pharmaceutical-sciences-js" */),
  "component---src-pages-self-paced-module-js": () => import("./../../../src/pages/self-paced-module.js" /* webpackChunkName: "component---src-pages-self-paced-module-js" */),
  "component---src-pages-video-topic-series-js": () => import("./../../../src/pages/video-topic-series.js" /* webpackChunkName: "component---src-pages-video-topic-series-js" */),
  "component---src-templates-career-profile-js": () => import("./../../../src/templates/careerProfile.js" /* webpackChunkName: "component---src-templates-career-profile-js" */)
}

